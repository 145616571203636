/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Iframe from 'react-iframe';
import Layout from '../components/layout';
import GenericWrapper from '../components/Wrapper';
// import galleryTest from '/gallery-test';

const GalleryPage = () => (
  <Layout>
    <section sx={{ py: '100px', variant: 'invert' }}>
      <GenericWrapper>
        <h1>Before gallery</h1>

        {/* <div
          id="sprinklr-embed-control-734-ZW1iZWQ6NWRkZGE1OTc4ZTZmMjc2NzE1YjM0OWQz"
          className="swe_embed_controls"
        ></div>
        <div id="sprinklr-embed-734-ZW1iZWQ6NWRkZGE1OTc4ZTZmMjc2NzE1YjM0OWQz" className="swe_embed"></div> */}

        {/* <script type="text/javascript" src="/test.js" /> */}
        {/* <script
          id="sprinklr-embed-loader-734-ZW1iZWQ6NWRkZGE1OTc4ZTZmMjc2NzE1YjM0OWQz"
          type="text/javascript"
          src="/gallery.js"
          // src="https://gallery-prod2.sprinklr.com/clients/734/embeds/ZW1iZWQ6NWRkZGE1OTc4ZTZmMjc2NzE1YjM0OWQz/script.js"
        /> */}

        <h1>After GAllery Markup</h1>
        {/* <Iframe url="https://5de5bf5a860cbea752f70f86--zen-kirch-e474c3.netlify.com/" /> */}
      </GenericWrapper>
    </section>
  </Layout>
);

export default GalleryPage;
